import { CdkOverlayOrigin, OverlayModule } from '@angular/cdk/overlay';
import { NgClass, NgFor, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { iconArrowsSortDownSmall, iconArrowsSortUpSmall, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';
import type { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { Recall2ButtonPrimaryComponent, Recall2ButtonSecondaryComponent } from '../../../buttons';
import { Recall2InputNumberComponent } from '../../../form/components/recall2-input-number';
import { Recall2InputTextComponent } from '../../../form/components/recall2-input-text';
import { Recall2RadioButtonGroupV2Component } from '../../../form/components/recall2-radio-button-group-v2';
import type { InputNumberProperty, InputTextProperty, RadioButtonV2Property } from '../../../form/model';
import { CheckboxProperty, RadioButtonV2GroupProperty } from '../../../form/model';
import { Recall2FilterAutocompleteComponent } from '../../../overlay/components/filter/recall2-filter-autocomplete/recall2-filter-autocomplete.component';
import { Recall2FilterCheckboxComponent } from '../../../overlay/components/filter/recall2-filter-checkbox/recall2-filter-checkbox.component';
import { Recall2FilterDateComponent } from '../../../overlay/components/filter/recall2-filter-date/recall2-filter-date.component';
import { Recall2FilterUserRolesComponent } from '../../../overlay/components/filter/recall2-filter-user-roles/recall2-filter-user-roles.component';
import { Recall2FilterYearComponent } from '../../../overlay/components/filter/recall2-filter-year/recall2-filter-year.component';
import type {
  FilterComparator,
  IRecall2FilterAutocompleteConfig,
  IRecall2FilterAutocompleteParam,
  IRecall2FilterDateParam,
  IRecall2FilterParam,
  IRecall2FilterRadioConfig,
  IRecall2FilterSelectableValue,
  IRecall2FilterSelectV2Config,
  IRecall2FilterUserRolesParam,
  Recall2FilterV2Config,
} from '../../../overlay/models/filter.model';
import {
  EAutocompleteFilterComparator,
  EDateFilterComparator,
  EFilterTemplates,
  ETextFilterComparator,
} from '../../../overlay/models/filter.model';
import { onClickOverlayOutside } from '../../../utils/cdk-overlay/cdk-overlay.utils';
import type { TableService } from '../../services/table.service';
import { TableServiceFactory } from '../../services/table-factory.service';
import { isDateFilterButtonEnabled } from '../../utils/table-filters.utils';
import type { FiltersConfigurationGroup } from './models/filter-configuration-group';
import { MoreFiltersScrollService } from './services/scroll/more-filters-scroll.service';

const DEFAULT_TEXT_FILTER_VALIDATOR = [
  Validators.pattern(/^[\d-_ ,.:A-Za-zÀ-ÿĄąĆćČčĎďĐđĘęĚěĞğİıŁłŃńŇňŐőŒœŘřŚśŠšţŤťŰűŸŹźŻżŽžǧ]+$/),
];

@Component({
  selector: 'app-more-filters',
  templateUrl: './more-filters.component.html',
  styleUrls: ['./more-filters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslateModule,
    MatExpansionModule,
    SVGIconComponent,
    Recall2FilterDateComponent,
    Recall2FilterUserRolesComponent,
    Recall2FilterYearComponent,
    Recall2FilterAutocompleteComponent,
    Recall2FilterCheckboxComponent,
    OverlayModule,
    Recall2ButtonPrimaryComponent,
    Recall2ButtonSecondaryComponent,
    Recall2InputNumberComponent,
    Recall2InputTextComponent,
    Recall2RadioButtonGroupV2Component,
    NgTemplateOutlet,
    NgSwitch,
    NgSwitchCase,
    NgClass,
    NgFor,
    NgIf,
  ],
})
export class MoreFiltersComponent implements OnInit, OnChanges {
  @Input() filtersConfig: Recall2FilterV2Config[] = [];
  @Input() set tableId(tableKey: string) {
    this.tableKey = tableKey;
    this.tableService = this.tableFactory.get(tableKey);
    this.activeFilters$ = this.tableService.activeFilters$.pipe(takeUntil(this.destroy$));
  }
  @ViewChild('overlayOrigin') overlayOrigin: CdkOverlayOrigin;

  tableKey: string;
  tableService: TableService;
  activeFilters$: Observable<IRecall2FilterParam[]>;

  searchProperty: InputTextProperty;
  textProperty: InputTextProperty;
  numberProperty: InputTextProperty;
  yearProperty: InputNumberProperty;
  searchControl: FormControl = new UntypedFormControl();
  numberControl: FormControl = new UntypedFormControl();
  MIN_YEAR = 1970;
  MAX_YEAR = new Date().getFullYear();

  /**
   * For dates, we directly depend on the filter param provided by recall2-filter-date
   */
  dateFilterParam: IRecall2FilterDateParam;
  autocompleteFilterParam: IRecall2FilterAutocompleteParam;
  userRolesFilterParam: IRecall2FilterUserRolesParam;
  yearControl: FormControl = new UntypedFormControl({ value: null, disabled: false }, [
    Validators.pattern('^[0-9]*$'),
    Validators.min(this.MIN_YEAR),
    Validators.max(this.MAX_YEAR),
  ]);

  filtersConfigList: FiltersConfigurationGroup[] = [];
  filteredList: FiltersConfigurationGroup[] = [];
  expandedFilter = '';
  EFilterTemplates = EFilterTemplates;
  groupedFiltersConfig: FiltersConfigurationGroup[] = [];
  isOpen = false;
  viewAllFilterOptions = false;
  isButtonEnabled = false;
  currentFilterType: EFilterTemplates;

  private filtersFormGroup = new UntypedFormGroup({});
  private destroy$ = new Subject<void>();

  constructor(
    private iconsRegistry: SVGIconsRegistry,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private tableFactory: TableServiceFactory,
    private moreFiltersScrollService: MoreFiltersScrollService,
  ) {
    this.iconsRegistry.registerIcons([iconArrowsSortDownSmall, iconArrowsSortUpSmall]);
  }

  ngOnInit(): void {
    this.initProperties();
    this.initFormGroup();
    this.watchSearchChanges();
    this.watchChangesToEnableButton();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filtersConfig && Array.isArray(changes.filtersConfig.currentValue)) {
      this.groupFilters();
      this.generateProperties();
    }
  }

  onScroll(): void {
    this.moreFiltersScrollService.sendScrollEvent();
  }

  onFilterOpened(filterConfig: Recall2FilterV2Config): void {
    if (filterConfig.type === EFilterTemplates.DATE) {
      this.initialiseDateFilterParam(filterConfig);
    } else if (filterConfig.type === EFilterTemplates.AUTOCOMPLETE) {
      this.initialiseAutoCompleteFilterParam(filterConfig);
    }

    this.expandedFilter = filterConfig.identifier;
    this.currentFilterType = filterConfig.type;
    this.setFilterTextValidator(filterConfig);

    if (filterConfig.type === EFilterTemplates.USER_ROLES) {
      this.isButtonEnabled = true;
      return;
    }
    this.isButtonEnabled = filterConfig.type === EFilterTemplates.SELECT;
    this.scrollContentIntoView();
    this.cdr.markForCheck();
  }

  private setFilterTextValidator(filter: Recall2FilterV2Config): void {
    if (filter.type !== EFilterTemplates.TEXT) {
      return;
    }

    if (filter.validators) {
      this.textProperty.control.setValidators(filter.validators);
      return;
    }

    this.textProperty.control.setValidators(DEFAULT_TEXT_FILTER_VALIDATOR);
  }

  onFilterClosed(filter: Recall2FilterV2Config): void {
    if (this.expandedFilter === filter.identifier) {
      this.expandedFilter = '';
    }
    if (filter.type === EFilterTemplates.RADIO || filter.type === EFilterTemplates.BOOLEAN) {
      this.clearRadioDefaultSelection(filter as IRecall2FilterRadioConfig);
    } else if (filter.type === EFilterTemplates.SELECT) {
      this.clearCheckboxDefaultSelection(filter);
    }
    this.viewAllFilterOptions = false;
    this.filtersFormGroup.reset();
  }

  onFilterApplied(filter: Recall2FilterV2Config): void {
    this.addFilter(filter);
    this.closeAndClearFilter();
  }

  toggleFilter(): void {
    this.isOpen = !this.isOpen;
  }

  onClickOutside(event: MouseEvent): void {
    onClickOverlayOutside(this.overlayOrigin, () => this.closeAndClearFilter(), event);
  }

  onDetach(): void {
    this.filtersConfigList.forEach(group => {
      group.filterList.forEach(filter => {
        this.clearRadioDefaultSelection(filter as IRecall2FilterRadioConfig);
        this.clearCheckboxDefaultSelection(filter as IRecall2FilterSelectV2Config);
      });
    });

    this.closeAndClearFilter();
  }

  toggleViewAllFilterOptions(): void {
    this.viewAllFilterOptions = !this.viewAllFilterOptions;
  }

  filterData(searchText: string): void {
    if (searchText) {
      const filteredItems: FiltersConfigurationGroup[] = [];
      this.filtersConfigList.forEach(group => {
        const filterList = group.filterList.filter(item =>
          this.translateService.instant(item.translationKey).toLowerCase().includes(searchText.toLowerCase()),
        );
        if (filterList && filterList.length > 0) {
          filteredItems.push({ translationKey: group.translationKey, filterList });
        }
      });

      this.filteredList = filteredItems;
    } else {
      this.filteredList = [...this.filtersConfigList];
    }

    this.cdr.markForCheck();
  }

  onChangeRadio(value: string | number | boolean): void {
    if (value === undefined || value === null) {
      this.isButtonEnabled = false;
      return;
    } else if (typeof value === 'number') {
      this.isButtonEnabled = true;
      return;
    }

    this.isButtonEnabled = String(value)?.length > 0;
  }

  onChangeDateFilter(selectedFilter: IRecall2FilterDateParam): void {
    this.dateFilterParam = selectedFilter;
    this.isButtonEnabled = isDateFilterButtonEnabled(selectedFilter);
  }

  onDateFilterValidationErrors(): void {
    this.isButtonEnabled = false;
  }

  onChangeAutocompleteFilter(selectedFilter: IRecall2FilterAutocompleteParam): void {
    this.autocompleteFilterParam = selectedFilter;
    this.isButtonEnabled = selectedFilter.value.length > 0;
  }

  onChangeUserRolesFilter(userRolesFilter: IRecall2FilterUserRolesParam): void {
    this.userRolesFilterParam = userRolesFilter;
    this.isButtonEnabled = !!userRolesFilter;
  }

  private watchChangesToEnableButton(): void {
    this.filtersFormGroup.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(groupValue => {
      this.isButtonEnabled =
        (groupValue?.textControl?.trim().length > 0 && this.textProperty.control.valid) ||
        !!groupValue?.fromDateControl ||
        !!groupValue?.toDateControl ||
        !!groupValue?.numberControl ||
        this.yearValidate(groupValue?.yearControl) ||
        this.currentFilterType === EFilterTemplates.SELECT;
    });
  }

  private yearValidate(yearValue): boolean {
    return yearValue >= this.MIN_YEAR && yearValue <= this.MAX_YEAR;
  }

  private groupFilters(): void {
    this.groupedFiltersConfig = [];
    const filteredFiltersWithGroup = this.filtersConfig.filter(filterItem => !!filterItem.group);

    filteredFiltersWithGroup.forEach(config => {
      const foundElement = this.groupedFiltersConfig.find(item => item.translationKey === config.group.translationKey);

      if (foundElement) {
        foundElement.filterList.push(config);
      } else {
        const newElement: FiltersConfigurationGroup = {
          translationKey: config.group.translationKey,
          filterList: [config],
        };
        this.groupedFiltersConfig.push(newElement);
      }
    });
  }

  private initialiseDateFilterParam(filterConfig: Recall2FilterV2Config): void {
    this.dateFilterParam = this.getMappedFilter(
      filterConfig,
      null,
      EDateFilterComparator.BETWEEN,
    ) as IRecall2FilterDateParam;
  }

  private initialiseAutoCompleteFilterParam(filterConfig: IRecall2FilterAutocompleteConfig): void {
    this.autocompleteFilterParam = {
      identifier: filterConfig.identifier,
      translationKey: filterConfig.translationKey,
      placeholderKey: filterConfig.placeholderKey,
      type: filterConfig.type,
      items: filterConfig.items,
      comparator: EAutocompleteFilterComparator.EQUALS,
      value: [],
    };
  }

  private getMappedFilter(
    filterConfig: Recall2FilterV2Config,
    value: string[],
    comparator?: FilterComparator,
  ): IRecall2FilterParam {
    const { identifier, translationKey, type, allowedComparators } = filterConfig;
    const mappeddFilter: IRecall2FilterParam = {
      identifier,
      translationKey,
      comparator: comparator ?? ETextFilterComparator.EQUALS,
      type,
      value,
    };

    if (allowedComparators) {
      mappeddFilter.allowedComparators = allowedComparators;
    }

    return mappeddFilter;
  }

  private getFilterValues(filter): string[] {
    switch (filter.type) {
      case EFilterTemplates.TEXT: {
        return [this.textProperty.control.value];
      }
      case EFilterTemplates.SELECT: {
        return filter.propertyList.filter(prop => prop.control.value).map(property => property.id);
      }
      case EFilterTemplates.RADIO:
      case EFilterTemplates.BOOLEAN: {
        return [filter.property.control.value];
      }
      case EFilterTemplates.YEAR: {
        return [this.yearControl.value.toString()];
      }
      case EFilterTemplates.NUMBER: {
        return [this.numberControl.value];
      }
    }
  }

  private closeAndClearFilter(): void {
    this.expandedFilter = '';
    this.isOpen = false;
    this.filtersFormGroup.reset();
  }

  private clearRadioDefaultSelection(item: IRecall2FilterRadioConfig): void {
    if (item.property) {
      item.property.control.setValue(null);
    }
  }

  private clearCheckboxDefaultSelection(item: IRecall2FilterSelectV2Config): void {
    if (item.propertyList) {
      item.propertyList.forEach(property => {
        property.control.setValue(null);
        property.isChecked = false;
      });
    }
  }

  private watchSearchChanges(): void {
    this.searchControl.valueChanges.pipe(debounceTime(300)).subscribe((value: string) => {
      this.filterData(value);
    });
  }

  private initProperties(): void {
    this.searchProperty = {
      name: 'searchMoreFilters',
      control: this.searchControl,
      hasTitle: false,
      hasTooltip: false,
      required: false,
      translationKey: 'more-filters-component.search-property',
      htmlValidators: null,
    };
    this.textProperty = {
      name: 'textMoreFilters',
      control: new UntypedFormControl('', DEFAULT_TEXT_FILTER_VALIDATOR),
      hasTitle: false,
      hasTooltip: false,
      required: false,
      translationKey: 'shared.table-filters.text-property',
      htmlValidators: null,
    };
    this.numberProperty = {
      name: 'numberMoreFilters',
      control: this.numberControl,
      hasTitle: false,
      hasTooltip: false,
      required: false,
      translationKey: 'shared.table-filters.number-property',
      htmlValidators: null,
    };
    this.yearProperty = {
      name: 'yearMoreFilters',
      control: this.yearControl,
      hasTitle: false,
      hasTooltip: false,
      required: false,
      translationKey: 'shared.table-filters.year-property',
      htmlValidators: null,
    };
  }

  private initFormGroup(): void {
    this.filtersFormGroup.addControl('textControl', this.textProperty.control);
    this.filtersFormGroup.addControl('numberControl', this.numberControl);
    this.filtersFormGroup.addControl('yearControl', this.yearControl);
  }

  private generateProperties(): void {
    this.filtersConfigList = [];

    this.groupedFiltersConfig.forEach(group => {
      const groupItem = {
        translationKey: group.translationKey,
        filterList: group.filterList.map(filter => {
          if (filter.type === EFilterTemplates.SELECT) {
            filter.propertyList = filter.itemsList.map(item => this.getCheckboxProperty(item));
          } else if (filter.type === EFilterTemplates.RADIO || filter.type === EFilterTemplates.BOOLEAN) {
            const radioProperties = filter.itemsList.map(item => this.getRadioProperty(item));
            filter.property = this.getRadioButtonV2GroupProperty(filter.identifier, radioProperties);
            this.filtersFormGroup.addControl('radioControl', filter.property.control);
          }
          return filter;
        }),
      };
      this.filtersConfigList.push(groupItem);
    });

    this.filteredList = [...this.filtersConfigList];
  }

  private getCheckboxProperty(item: IRecall2FilterSelectableValue): CheckboxProperty {
    return new CheckboxProperty(
      item.translationKey,
      false,
      item.translationKey,
      false,
      false,
      new FormControl(),
      item.value,
    );
  }

  private getRadioProperty(item: IRecall2FilterSelectableValue): RadioButtonV2Property {
    return { translationKey: item.translationKey, value: item.value };
  }

  private getRadioButtonV2GroupProperty(name: string, options: RadioButtonV2Property[]): RadioButtonV2GroupProperty {
    return new RadioButtonV2GroupProperty(name, null, new FormControl(), options);
  }

  private addFilter(filter: Recall2FilterV2Config): void {
    switch (filter.type) {
      case EFilterTemplates.DATE: {
        this.tableService.addFilter(this.dateFilterParam);
        break;
      }
      case EFilterTemplates.AUTOCOMPLETE: {
        this.tableService.addFilter(this.autocompleteFilterParam);
        break;
      }
      case EFilterTemplates.USER_ROLES: {
        this.tableService.addFilter(this.userRolesFilterParam);
        break;
      }
      default: {
        const selectedValues = this.getFilterValues(filter);
        const mappedFilter = this.getMappedFilter(filter, selectedValues);
        this.tableService.addFilter(mappedFilter);
      }
    }
  }

  private scrollContentIntoView(): void {
    const panelSelector = '.mat-expansion-panel-content:not([style*="visibility: hidden"]) recall2-button-primary';

    setTimeout(() => {
      const panelContent = document.querySelector(panelSelector);

      if (panelContent) {
        panelContent.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }, 200);
  }
}
