export enum BrandsId {
  VOLKSWAGEN_PKW = 1,
  VOLKSWAGEN_NUTZFAHRZEUGE = 2,
  AUDI = 3,
  SKODA = 4,
  SEAT = 5,
  BENTLEY = 6,
  PORSCHE = 7,
  LAMBORGHINI = 8,
  BUGATTI = 9,
  MAN = 10,
  SCANIA = 11,
  DUCATI = 12,
  MAN_LATIN_AMERICA = 13,
  FORD = 14,
}
