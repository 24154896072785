import { AvailableAps } from './models';

export const AVAILABLE_APS_LIST: string[] = Object.entries(AvailableAps).map(([_key, value]) => value);

export const BRANDS_WITH_APS = [
  {
    name: 'volkswagen',
    aps: [
      {
        name: AvailableAps.Volkswagen,
        all: true,
        translation: 'Volkswagen APS',
      },
      {
        name: AvailableAps.VolkswagenMsk,
        translation: 'Volkswagen MSK A&V',
      },
    ],
  },
  {
    name: 'nutzfahrzeuge',
    aps: [
      {
        name: AvailableAps.Nutzfahrzeuge,
      },
    ],
  },
  {
    name: 'audi',
    aps: [
      {
        name: AvailableAps.Audi,
      },
    ],
  },
  {
    name: 'skoda',
    aps: [
      {
        name: AvailableAps.Skoda,
      },
    ],
  },
  {
    name: 'seat',
    aps: [
      {
        name: AvailableAps.Seat,
      },
    ],
  },
  {
    name: 'bentley',
    aps: [
      {
        name: AvailableAps.Bentley,
      },
    ],
  },
  {
    name: 'porsche',
    aps: [
      {
        name: AvailableAps.Porsche,
      },
    ],
  },
  {
    name: 'lamborghini',
    aps: [
      {
        name: AvailableAps.Lamborghini,
      },
    ],
  },
  {
    name: 'man',
    aps: [
      {
        name: AvailableAps.Man,
      },
    ],
  },
  {
    name: 'ford',
    aps: [
      {
        name: AvailableAps.Ford,
      },
    ],
  },
] as const;
