import { NgFor, NgIf, UpperCasePipe } from '@angular/common';
import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { WINDOW } from '@recall2/globals';
import { Recall2RadioButtonGroupV2Component } from '@recall2/ui/form/components/recall2-radio-button-group-v2';
import type { RadioButtonV2GroupProperty } from '@recall2/ui/form/model';
import { LanguageCode } from '@recall2/ui/i18n';
import { Recall2IconAddCircleComponent } from '@recall2/ui/icons';
import { TabTitleService } from '@recall2/ui/tab-title';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DownloadApsService } from '../../rest-api/download-aps/download-aps.service';
import { Recall2ButtonBrandManufacturerComponent } from './components/recall2-button-brand-manufacturer/recall2-button-brand-manufacturer.component';
import { AVAILABLE_APS_LIST, BRANDS_WITH_APS } from './landing-page.constants';
import type { APS, BrandAPS } from './models';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    Recall2ButtonBrandManufacturerComponent,
    NgIf,
    Recall2RadioButtonGroupV2Component,
    Recall2IconAddCircleComponent,
    UpperCasePipe,
    TranslateModule,
  ],
})
export class LandingPageComponent implements OnInit, OnDestroy {
  brandsAPS: BrandAPS[] = [];
  selectedBrand: BrandAPS;

  selectorManufacturersRadioButtonV2GroupProperty: RadioButtonV2GroupProperty = {
    name: 'selector-manufacturer-radio-button-group',
    translationKey: 'landing.aps.download.select.manufacturers.title',
    control: new FormControl(),
    required: false,
    hasTooltip: false,
    options: [],
    showHorizontal: false,
  };

  selectorLanguagesRadioButtonV2GroupProperty: RadioButtonV2GroupProperty = {
    name: 'selectorLanguage-radio-button-group',
    translationKey: 'landing.aps.download.select.title',
    control: new FormControl(),
    required: true,
    hasTooltip: false,
    options: [],
    showHorizontal: false,
  };

  showSelectorManufacturers = false;
  showSelectorLang = false;

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(WINDOW) private window: Window,
    private router: Router,
    private downloadApsService: DownloadApsService,
    private tabTitleService: TabTitleService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.setBrowserTabTitle();
    this.getAvailableAPS();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onCreateNewNotification(): void {
    this.router.navigate(['notifications', 'create', 'description']);
  }

  onSelectBrand(brand: BrandAPS): void {
    this.selectorLanguagesRadioButtonV2GroupProperty.control.reset();
    this.selectorManufacturersRadioButtonV2GroupProperty.control.reset();

    if (!brand.enabled) {
      this.showSelectorManufacturers = false;
      this.showSelectorLang = false;
      return;
    }

    if (brand.aps.length === 1 && brand.aps[0].languages.length === 1) {
      this.showSelectorManufacturers = false;
      this.showSelectorLang = false;

      this.downloadTemplate(brand.aps[0].name, brand.aps[0].languages[0]);
      return;
    }
    this.selectedBrand = brand;
    this.generateManufacturersRadioButtons(brand.aps);

    this.showSelectorLang = true;
    const aps = brand.aps.find(aps => !!aps.all) || brand.aps[0];
    this.generateLanguagesRadioButtons(aps);
  }

  onChangeManufacturer(value: string | boolean): void {
    if (!value) {
      return;
    }
    const manufacturer: APS = this.getSelectedManufacturer(String(value));
    if (manufacturer.languages.length === 1) {
      this.showSelectorLang = false;

      this.downloadTemplate(manufacturer.name, manufacturer.languages[0]);
      return;
    }

    this.showSelectorLang = true;
    this.generateLanguagesRadioButtons(manufacturer);
  }

  onChangeLanguage(value: string | boolean): void {
    if (!value) {
      return;
    }
    const manufacturer: APS =
      this.selectedBrand.aps.length === 1
        ? this.selectedBrand.aps[0]
        : this.getSelectedManufacturer(this.selectorManufacturersRadioButtonV2GroupProperty.control.value);
    const selectedLang: LanguageCode = this.getSelectedLanguage(manufacturer, String(value));
    if (selectedLang) {
      this.resetSelectorLang();
      this.downloadTemplate(manufacturer.name, selectedLang);
      this.cdr.markForCheck();
    }
  }

  private getSelectedManufacturer(selected: string): APS {
    return this.selectedBrand.aps.find(aps => aps.name === selected);
  }

  private getSelectedLanguage(selectedManufacturer: APS, selectedLang: string): LanguageCode {
    return this.selectedBrand.aps
      .find(aps => aps === selectedManufacturer)
      .languages.find(lang => lang === selectedLang);
  }

  private getAvailableAPS(): void {
    this.downloadApsService
      .getAvailableAPS(AVAILABLE_APS_LIST, [
        LanguageCode.German,
        LanguageCode.EnglishGB,
        LanguageCode.EnglishUS,
        LanguageCode.Spanish,
      ])
      .pipe(takeUntil(this.destroyed$))
      .subscribe(brands => {
        this.brandsAPS = BRANDS_WITH_APS.map(brandAps => {
          const aps = brandAps.aps.map(aps => {
            return {
              ...aps,
              languages: (brands.find(b => b.brandName === aps.name)?.languages as LanguageCode[]) || [],
            };
          });
          return {
            ...brandAps,
            enabled: aps.some(aps => aps.languages.length > 0),
            aps,
          };
        });

        this.cdr.markForCheck();
      });
  }

  private downloadTemplate(apsName: string, language: LanguageCode): void {
    this.downloadApsService
      .getDownloadUrl(apsName, language)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(url => {
        this.window.open(url);
      });
  }

  private generateManufacturersRadioButtons(manufacturersAPS: APS[]): void {
    this.selectorManufacturersRadioButtonV2GroupProperty.options = [];

    if (manufacturersAPS.length <= 1) {
      this.showSelectorManufacturers = false;

      return;
    }

    for (const aps of manufacturersAPS) {
      if (aps.languages.length === 0) {
        continue;
      }

      this.selectorManufacturersRadioButtonV2GroupProperty.options.push({
        rawText: aps.translation,
        dataCy: `manufacturer-${aps.name}`,
        value: aps.name,
      });
    }
    this.selectorManufacturersRadioButtonV2GroupProperty.control.setValue(manufacturersAPS[0].name);
    this.showSelectorManufacturers = this.selectorManufacturersRadioButtonV2GroupProperty.options.length > 1;
    this.cdr.markForCheck();
  }

  private generateLanguagesRadioButtons(aps: APS): void {
    this.selectorLanguagesRadioButtonV2GroupProperty.options = [];
    for (const lang of aps.languages) {
      this.selectorLanguagesRadioButtonV2GroupProperty.options.push({
        translationKey: `landing.aps.download.select.${lang}`,
        dataCy: `language-${lang}`,
        value: lang,
      });
    }

    this.cdr.markForCheck();
  }

  private resetSelectorLang(): void {
    this.selectorLanguagesRadioButtonV2GroupProperty.control.setValue('');
  }

  private setBrowserTabTitle(): void {
    this.tabTitleService.setTitleByKey('preliminary.landing.tab-title');
  }
}
