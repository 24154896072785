<div [ngClass]="{'error': isSubmitted && brandManufacturerSelectorConfiguration.selectedManufacturers.length === 0}">
  <div
    *ngIf="brandManufacturerSelectorConfiguration.translationKeyTitle"
    id="visibility-manufacturer-label"
    class="title"
  >
    {{brandManufacturerSelectorConfiguration.translationKeyTitle | translate}}
  </div>
  <div
    *ngIf="brandManufacturerSelectorConfiguration.translationKeySubtitle"
    id="choose-manufacturer-label"
    class="subtitle"
  >
    {{brandManufacturerSelectorConfiguration.translationKeySubtitle | translate}}
  </div>
  <div class="row rc2-mar--bottom--xl brand-manufacturer-list">
    <div
      *ngFor="let entry of brandManufacturerSelectorConfiguration.brandManufacturers"
      class="brand-manufacturer position-relative"
      (click)="selectBrandManufacturer(entry)"
      matTooltipPosition="above"
      matTooltipClass="rc2-tooltip"
      [matTooltip]="(entry | isBrandDisabled:brandManufacturerSelectorConfiguration.alreadyForwardedManufacturers) ?
        ('brandManufacturerSelector.brand-disabled' | translate) : ''"
      [ngClass]="{
           'selectedBrand': isManufacturerSelected(entry),
           'defaultBrand': isManufacturerDefault(entry),
           'defaultBrandWithMoreManufacturers': hasBrandMoreManufacturerAndDefault(entry),
           'multipleBrandManufacturersSelected': entry.manufacturers.length > 1 && isSelected(entry.brand.id),
           'disabled-manufacturer': entry | isBrandDisabled:brandManufacturerSelectorConfiguration.alreadyForwardedManufacturers
          }"
    >
      <ng-container *ngIf="isManufacturerSelected(entry) || isManufacturerDefault(entry) ||
                          (isManufacturerWithMoreSelected(entry) && checkMoreManufacturersSelected(entry) < 2) ||
                          (hasBrandMoreManufacturerAndDefault(entry) && checkMoreManufacturersSelected(entry) < 2)">
        <div class="icon-check-background-solid"></div>
        <recall2-icon-check
          class="position-absolute-check"
          [ngClass]="{'default-select-check': isStateDefault(entry), 'brand-select-check': isStateBrand(entry)}"
        >
        </recall2-icon-check>
      </ng-container>
      <div
        *ngIf="checkMoreManufacturersSelected(entry) > 1"
        class="position-absolute-icon custom-icon-default"
        [ngClass]="isSelectedWithDefault(entry)"
      >
        {{checkMoreManufacturersSelected(entry)}}
      </div>

      <recall2-img-audi *ngIf="entry.brand.id === brandsId.AUDI"></recall2-img-audi>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1 && entry.brand.id === brandsId.AUDI"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-bentley *ngIf="entry.brand.id === brandsId.BENTLEY"></recall2-img-bentley>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1 && entry.brand.id === brandsId.BENTLEY"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-porsche *ngIf="entry.brand.id === brandsId.PORSCHE"></recall2-img-porsche>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1 && entry.brand.id === brandsId.PORSCHE"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-skoda *ngIf="entry.brand.id === brandsId.SKODA"></recall2-img-skoda>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1 && entry.brand.id === brandsId.SKODA"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-seat *ngIf="entry.brand.id === brandsId.SEAT"></recall2-img-seat>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1 && entry.brand.id === brandsId.SEAT"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-volkswagen *ngIf="entry.brand.id === brandsId.VOLKSWAGEN_PKW"></recall2-img-volkswagen>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1  && entry.brand.id === brandsId.VOLKSWAGEN_PKW"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-nutzfahrzeuge *ngIf="entry.brand.id === brandsId.VOLKSWAGEN_NUTZFAHRZEUGE">
      </recall2-img-nutzfahrzeuge>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1  && entry.brand.id === brandsId.VOLKSWAGEN_NUTZFAHRZEUGE"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-man *ngIf="entry.brand.id === brandsId.MAN"></recall2-img-man>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1  && entry.brand.id === brandsId.MAN"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-ducati *ngIf="entry.brand.id === brandsId.DUCATI"></recall2-img-ducati>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1  && entry.brand.id === brandsId.DUCATI"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-scania *ngIf="entry.brand.id === brandsId.SCANIA"></recall2-img-scania>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1  && entry.brand.id === brandsId.SCANIA"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-bugatti *ngIf="entry.brand.id === brandsId.BUGATTI"></recall2-img-bugatti>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1  && entry.brand.id === brandsId.BUGATTI"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-lamborghini *ngIf="entry.brand.id === brandsId.LAMBORGHINI"></recall2-img-lamborghini>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1  && entry.brand.id === brandsId.LAMBORGHINI"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>

      <recall2-img-ford *ngIf="entry.brand.id === brandsId.FORD"></recall2-img-ford>
      <recall2-icon-arrow-down
        *ngIf="entry.manufacturers.length > 1  && entry.brand.id === brandsId.FORD"
        class="position-absolute"
        [rc2IconStyles]="'rc2-icon--color--blue'"
      ></recall2-icon-arrow-down>
    </div>
  </div>
  <ng-container *ngFor="let entry of brandManufacturerSelectorConfiguration.brandManufacturers">
    <recall2-choose-manufacturer
      *ngIf="entry.manufacturers.length > 1 && isSelected(entry.brand.id)"
      id="{{entry.brand.id}}"
      [manufacturerEntries]="getManufacturesEntries(entry.brand.id)"
      [defaultManufacturerEntry]="brandManufacturerSelectorConfiguration.defaultManufacturer"
      [selectedManufacturerEntries]="brandManufacturerSelectorConfiguration.selectedManufacturers"
      [forwardedManufacturerIds]="forwardedManufacturerIds"
      (updateManufacturer)="updateSelectedManufacturerEntries($event)"
    >
    </recall2-choose-manufacturer>
  </ng-container>
</div>

<p
  class="error-message"
  *ngIf="isSubmitted && brandManufacturerSelectorConfiguration.selectedManufacturers.length === 0"
>
  {{ 'brandManufacturerSelector.required' | translate }}
</p>
