export enum EStatus {
  DRAFT = 'DRAFT',
  PROCESSING = 'PROCESSING',
  RESOLVED = 'RESOLVED',
  AWAIT_DECISION = 'AWAIT_DECISION',
  COMPLETED = 'COMPLETED',
  DISCARDED = 'DISCARDED',
  TO_REVISE_REVIEWER = 'TO_REVISE_REVIEWER',
  TO_REVISE_BRAND_OFFICE = 'TO_REVISE_BRAND_OFFICE',
  REVIEW = 'REVIEW',
  APPROVED = 'APPROVED',
  ACCEPTED = 'ACCEPTED',
  LINKED = 'LINKED',
  IN_PROGRESS = 'IN_PROGRESS',
  DRAFT_PROGRESS = 'DRAFT_PROGRESS',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  FINAL = 'FINAL',
  STOPPED = 'STOPPED',
  IN_PREPARATION = 'IN_PREPARATION',
  READY = 'READY',
  CLOSED = 'CLOSED',
  UNANSWERED = 'UNANSWERED',
  INVITED = 'INVITED',
  DECLINED = 'DECLINED',
  RELEASED = 'RELEASED',
  PLANNING = 'PLANNING',
  EXCLUDED = 'EXCLUDED',
  INCLUDED = 'INCLUDED',
  DELETED = 'DELETED',
  COMPLETE_LOCK = 'COMPLETE_LOCK',
  BLOCKED = 'BLOCKED',
  ASSIGNED = 'ASSIGNED',
  CHECK_ASSIGNMENT = 'CHECK_ASSIGNMENT',
  UNKNOWN_LOCATION = 'UNKNOWN_LOCATION',
  SCRAPPED = 'SCRAPPED',
  ADDED = 'ADDED',
  REMOVED = 'REMOVED',
  OPEN = 'OPEN',
  PARTIALLY_PROCESSED = 'PARTIALLY_PROCESSED',
  MANUALLY_CLOSED = 'MANUALLY_CLOSED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  DENIED = 'DENIED',
  RECEIVED = 'RECEIVED',
  PROCESSED = 'PROCESSED',
  EXPORTED = 'EXPORTED',
  FAILED = 'FAILED',
}
