import { NgClass, NgIf } from '@angular/common';
import type { OnChanges, SimpleChanges } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTableModule } from '@angular/material/table';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2IconDeleteComponent } from '../../../icons/recall2-icon-delete/recall2-icon-delete.component';
import { Recall2IconDownloadComponent } from '../../../icons/recall2-icon-download/recall2-icon-download.component';
import { FileSizePipe } from '../../../pipes/file-size/file-size.pipe';
import type { AttachmentFile } from '../../models/attachment-file.model';

type ActionColumn = 'download' | 'delete';

@Component({
  selector: 'recall2-file-list',
  templateUrl: './file-list.component.html',
  styleUrls: ['./file-list.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    MatTableModule,
    TranslateModule,
    FileSizePipe,
    Recall2IconDownloadComponent,
    Recall2IconDeleteComponent,
  ],
})
export class FileListComponent implements OnChanges {
  @Input() files: AttachmentFile[] = [];
  @Input() hideDownloadButtons = false;
  @Input() hideDeleteButtons = false;
  @Input() isOverlay = false;
  @Input() loading = false;
  @Output() downloadAll = new EventEmitter();
  @Output() download = new EventEmitter<AttachmentFile>();
  @Output() delete = new EventEmitter<AttachmentFile>();

  readonly allColumns: (keyof AttachmentFile | ActionColumn)[] = [
    'download',
    'filename',
    'extension',
    'size',
    'delete',
  ];
  displayedColumns: (keyof AttachmentFile | ActionColumn)[] = [...this.allColumns];
  totalSize = 0;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['hideDownloadButtons']?.currentValue || changes['hideDeleteButtons']?.currentValue) {
      this.setDisplayedColumns();
    }

    if (changes['files']?.currentValue) {
      this.calculateTotalSize();
    }
  }

  onDelete(event: MouseEvent, attachment: AttachmentFile): void {
    event.stopPropagation();
    this.delete.emit(attachment);
  }

  private setDisplayedColumns(): void {
    let columns = [...this.allColumns];
    if (this.hideDownloadButtons) {
      columns = columns.filter(x => x !== 'download');
    }
    if (this.hideDeleteButtons) {
      columns = columns.filter(x => x !== 'delete');
    }
    this.displayedColumns = columns;
  }

  private calculateTotalSize(): void {
    this.totalSize = this.files.map(f => f.size).reduce((x, y) => x + y, 0);
  }
}
