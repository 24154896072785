<ng-container [ngSwitch]="brandId">
  <recall2-icon-logo-audi
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.AUDI"
  ></recall2-icon-logo-audi>
  <recall2-icon-logo-bentley
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.BENTLEY"
  ></recall2-icon-logo-bentley>
  <recall2-icon-logo-man
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.MAN"
  ></recall2-icon-logo-man>
  <recall2-icon-logo-man
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.MAN_LATIN_AMERICA"
  ></recall2-icon-logo-man>
  <recall2-icon-logo-porsche
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.PORSCHE"
  ></recall2-icon-logo-porsche>
  <recall2-icon-logo-seat
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.SEAT"
  ></recall2-icon-logo-seat>
  <recall2-icon-logo-skoda
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.SKODA"
  ></recall2-icon-logo-skoda>
  <recall2-icon-logo-vw
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.VOLKSWAGEN_PKW"
  ></recall2-icon-logo-vw>
  <recall2-icon-logo-vw
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.VOLKSWAGEN_NUTZFAHRZEUGE"
  ></recall2-icon-logo-vw>
  <recall2-icon-logo-scania
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.SCANIA"
  ></recall2-icon-logo-scania>
  <recall2-icon-logo-bugatti
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.BUGATTI"
  ></recall2-icon-logo-bugatti>
  <recall2-icon-logo-lamborghini
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.LAMBORGHINI"
  ></recall2-icon-logo-lamborghini>
  <recall2-icon-logo-ducati
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.DUCATI"
  ></recall2-icon-logo-ducati>
  <recall2-icon-logo-ford
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchCase="BrandsId.FORD"
  ></recall2-icon-logo-ford>
  <recall2-icon-logo-recall
    [rc2IconStyles]="rc2IconStyles"
    *ngSwitchDefault
  ></recall2-icon-logo-recall>
</ng-container>
