<footer>
  <span><a href="/consent/imprint">{{ 'page-footer.imprint' | translate }}</a></span>
  <span><a href="/consent/condition-of-use">{{ 'page-footer.terms' | translate }}</a></span>
  <span><a href="/consent/data-protection-declaration">{{ 'page-footer.privacy' | translate }}</a></span>
  <span><a href="/consent/licenses">{{ 'page-footer.licenses' | translate }}</a></span>
  <span><a href="/consent/cookie-policy">{{ 'page-footer.cookie-policy' | translate }}</a></span>
  <span><a
      (click)="openSupportDialog()"
    >{{ 'page-footer.support' | translate }}</a></span>
</footer>
<div class="footer__banner">
  <img
    alt="Footer Logo"
    class="footer-logo"
    src="./assets/vwag/svg/vw_logo/footer_logo_vwag.svg"
  >
</div>
