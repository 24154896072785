import type { PipeTransform } from '@angular/core';
import { Pipe } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'formatDateDisplay',
  standalone: true,
})
export class FormatDateDisplayPipe implements PipeTransform {
  transform(value: Date | string | null): string {
    if (!value) {
      return '';
    }

    if (value instanceof Date) {
      // eslint-disable-next-line unicorn/prefer-number-properties
      return isNaN(value.getTime()) ? '' : dayjs(value).format('DD.MM.YYYY');
    }

    return String(value);
  }
}
