import type { OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { iconCloseMedium, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';

import { AuthUserQuery } from '../auth';
import { Recall2ButtonPrimaryComponent } from '../buttons';

@Component({
  selector: 'app-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [TranslateModule, SVGIconComponent, Recall2ButtonPrimaryComponent],
})
export class SupportModalComponent implements OnInit {
  idpMail = 'recall.2.support.vwag.r.wob@volkswagen.de';
  grpMail = 'recall.2.support.extern.vwag.r.wob@volkswagen.de';
  isGrpUser = false;

  constructor(
    public dialogRef: MatDialogRef<SupportModalComponent>,
    private iconsRegistry: SVGIconsRegistry,
    private authUserQuery: AuthUserQuery,
  ) {
    this.iconsRegistry.registerIcons([iconCloseMedium]);
  }

  ngOnInit(): void {
    this.isGrpUser = this.authUserQuery.isGrpUser();
  }

  onClose(): void {
    this.dialogRef.close(false);
  }
}
