<recall2-button-secondary
  cdkOverlayOrigin
  #overlayOrigin="cdkOverlayOrigin"
  data-cy="more-filters-button"
  class="rc2-mar--right--xxs"
  [class.active-button]="isOpen"
  (click)="toggleFilter()"
>
  {{ 'active-filters.add-filter-button' | translate }}
</recall2-button-secondary>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayLockPosition
  [cdkConnectedOverlayOrigin]="overlayOrigin"
  [cdkConnectedOverlayOpen]="isOpen"
  (overlayOutsideClick)="onClickOutside($event)"
  (detach)="onDetach()"
>
  <div class="more-filters">
    <div class="filter-list">
      <div class="filter-header">
        <recall2-input-text
          data-cy="more-filters-search-input"
          class="filter-search-input"
          [property]="searchProperty"
        ></recall2-input-text>
        <hr class="rc2-mar--bottom--xs rc2-mar--top--0" />
      </div>
      <div class="filter-content" (scroll)="onScroll()">
        <mat-accordion displayMode="flat">
          <ng-container *ngFor="let group of filteredList;">
            <div
              *ngIf="group.translationKey"
              class="filter-group-label cell-spacing"
              data-cy="more-filters-group"
            >{{ group.translationKey | translate }}</div>

            <ng-container *ngFor="let filter of group.filterList; let first = first; let last = last">
              <mat-expansion-panel
                hideToggle
                #panel
                class="'more-filters-filter-panel"
                [ngClass]="{
                  'more-filters-filter-panel--all-options': filter.type === EFilterTemplates.SELECT && viewAllFilterOptions}"
                [attr.data-cy]="'more-filters-filter-panel-' + filter.identifier"
                [expanded]="false"
                (opened)="onFilterOpened(filter)"
                (closed)="onFilterClosed(filter)"
              >
                <mat-expansion-panel-header [ngClass]="{ 'accordion-open': filter.identifier === expandedFilter }">
                  <mat-panel-title>
                    <div class="panel-container">
                      <recall2-svg-icon
                        data-cy="more-filters-arrow-up"
                        *ngIf="filter.identifier === expandedFilter"
                        name="arrows-sort-up"
                        size="small"
                        class="rc2-pad--top--xxs"
                      ></recall2-svg-icon>
                      <recall2-svg-icon
                        data-cy="more-filters-arrow-down"
                        *ngIf="filter.identifier !== expandedFilter"
                        name="arrows-sort-down"
                        size="small"
                        class="rc2-pad--bottom--xxs"
                      ></recall2-svg-icon>
                      <div
                        class="panel-container__title"
                        [attr.data-cy]="'more-filters-filter-' + filter.identifier"
                      >
                        {{ filter.translationKey | translate }}
                      </div>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngIf="panel.expanded">
                  <div
                    [attr.data-cy]="'more-filters-filter-content-' + filter.identifier"
                    class="more-filters-component"
                  >
                    <ng-container [ngSwitch]="filter.type">
                      <div *ngSwitchCase="EFilterTemplates.TEXT">
                        <ng-container *ngTemplateOutlet="text; context: { $implicit: filter }"></ng-container>
                      </div>
                      <div *ngSwitchCase="EFilterTemplates.NUMBER">
                        <ng-container *ngTemplateOutlet="number; context: { $implicit: filter }"></ng-container>
                      </div>
                      <div *ngSwitchCase="EFilterTemplates.DATE">
                        <ng-container *ngTemplateOutlet="date; context: { $implicit: filter }"></ng-container>
                      </div>
                      <div *ngSwitchCase="EFilterTemplates.SELECT">
                        <ng-container *ngTemplateOutlet="select; context: { $implicit: filter }"></ng-container>
                      </div>
                      <div *ngSwitchCase="EFilterTemplates.RADIO">
                        <ng-container *ngTemplateOutlet="radio; context: { $implicit: filter }"></ng-container>
                      </div>
                      <div *ngSwitchCase="EFilterTemplates.BOOLEAN">
                        <ng-container *ngTemplateOutlet="radio; context: { $implicit: filter }"></ng-container>
                      </div>
                      <div *ngSwitchCase="EFilterTemplates.YEAR">
                        <ng-container *ngTemplateOutlet="year; context: { $implicit: filter }"></ng-container>
                      </div>
                      <div *ngSwitchCase="EFilterTemplates.AUTOCOMPLETE">
                        <ng-container *ngTemplateOutlet="autocomplete; context: { $implicit: filter }"></ng-container>
                      </div>
                      <div *ngSwitchCase="EFilterTemplates.USER_ROLES">
                        <ng-container *ngTemplateOutlet="userRoles; context: { $implicit: filter }"></ng-container>
                      </div>
                    </ng-container>
                  </div>

                  <div
                    class="more-filter-buttons"
                    [ngClass]="{'more-filter-buttons--all-options': filter.type === EFilterTemplates.SELECT && viewAllFilterOptions}"
                    >
                    <div
                      *ngIf="filter.type === EFilterTemplates.SELECT"
                      (click)="toggleViewAllFilterOptions()"
                      class="view-all-toggle rc2-pad--s"
                    >
                      {{ viewAllFilterOptions ?
                        ('more-filters-component.view-less' | translate) :
                        ('more-filters-component.view-all' | translate) }}
                    </div>
                    <recall2-button-primary
                      data-cy="more-filters-submit-button"
                      class="rc2-pad--s rc2-pad--top--0 rc2-display--block more-filters-submit-button"
                      (click)="onFilterApplied(filter)"
                      [isDisabled]="!isButtonEnabled"
                    >
                      {{
                        filter.type === EFilterTemplates.TEXT
                          ? ('more-filters.add-button' | translate)
                          : ('shared.apply' | translate)
                      }}
                    </recall2-button-primary>
                  </div>
                </ng-container>
              </mat-expansion-panel>

              <hr
                *ngIf="last"
                class="rc2-mar--top--xs rc2-mar--bottom--xxs"
              />
            </ng-container>
          </ng-container>
          <div
            class="cell-spacing"
            *ngIf="filteredList.length === 0"
            data-cy="more-filters-no-entries"
          ><span class="rc2-color--grey-500">
              {{ 'common.no-entries-found' | translate }}
            </span></div>
        </mat-accordion>
      </div>
    </div>
  </div>
</ng-template>

<!-- TEMPLATES -->
<ng-template
  #text
  let-filter
>
  <div
    class="rc2-pad--s"
    data-cy="more-filters-text-filter"
  >
    <ng-container *ngTemplateOutlet="hint; context: { $implicit: filter }"></ng-container>
    <recall2-input-text [property]="textProperty"></recall2-input-text>
  </div>
</ng-template>

<ng-template
  #number
  let-filter
>
  <div
    class="rc2-pad--s"
    data-cy="more-filters-number-filter"
  >
    <ng-container *ngTemplateOutlet="hint; context: { $implicit: filter }"></ng-container>
    <recall2-input-number [property]="numberProperty"></recall2-input-number>
  </div>
</ng-template>

<ng-template
  #select
  let-filter
>
  <div
    class="rc2-pad--s"
    [class.view-less]="!viewAllFilterOptions"
    data-cy="more-filters-checkbox-filter"
  >
    <recall2-filter-checkbox
      *ngFor="let prop of filter.propertyList; let last = last"
      class="more-filters-checkbox-filter-option"
      [ngClass]="{ 'more-filters-checkbox-filter-option--all-options': last && viewAllFilterOptions }"
      [property]="prop"
    ></recall2-filter-checkbox>
  </div>
</ng-template>

<ng-template
  #radio
  let-filter
>
  <div
    class="rc2-pad--s"
    data-cy="more-filters-radio-filter"
  >
    <recall2-radio-button-group-v2
      [property]="filter.property"
      (radioChanged)="onChangeRadio($event)"
    ></recall2-radio-button-group-v2>
  </div>
</ng-template>

<ng-template
  #date
  let-filter
>
  <div
    class="rc2-pad--s"
    data-cy="more-filters-radio-filter"
  >
    <recall2-filter-date
      *ngIf="dateFilterParam"
      [currentSelectedFilter]="dateFilterParam"
      (filterChanged)="onChangeDateFilter($event)"
      (hasValidationErrors)="onDateFilterValidationErrors()"
    >
    </recall2-filter-date>
  </div>
</ng-template>

<ng-template
  #year
  let-filter
>
  <div
    class="rc2-pad--s"
    data-cy="more-filters-year-filter"
  >
    <recall2-filter-year [selectedYear]="yearProperty"></recall2-filter-year>
  </div>
</ng-template>

<ng-template
  #autocomplete
  let-filter
>
  <div
    class="rc2-pad--s"
    data-cy="more-filters-autocomplete-filter"
  >
    <recall2-filter-autocomplete
      *ngIf="autocompleteFilterParam"
      [currentFilterSelected]="autocompleteFilterParam"
      (filterSelected)="onChangeAutocompleteFilter($event)"
    ></recall2-filter-autocomplete>
  </div>
</ng-template>

<ng-template
  #userRoles
  let-filter
>
  <div
    class="rc2-pad--s"
    data-cy="more-filters-user-roles-filter"
  >
    <recall2-filter-user-roles
      [filterConfig]="filter"
      (filterChanged)="onChangeUserRolesFilter($event)"
    ></recall2-filter-user-roles>
  </div>
</ng-template>

<ng-template
  #hint
  let-filter
>
  <div
    *ngIf="filter.showHint"
    class="rc2-pad--bottom--s text-filter-hint"
    [innerHTML]="filter.translationKey + '.hint' | translate"
  ></div>
</ng-template>
