export const URL_PATHS = {
  TOPIC_APP_OVERVIEW: '/topics/',
  TOPIC_APP_CLEARING_OVERVIEW: '/clearings/',
  TOPIC_APP_VERIFICATION_TASK_OVERVIEW: '/verification-tasks/overview',
  TOPIC_APP_VERIFICATION_TASK_OVERVIEW_CLEARING: '/verification-tasks/clearing',
  COMMITTEE_APP_OVERVIEW: '/committees/',
  COMMITTEE_APP_MEETING_OVERVIEW: '/meetings/',
  COMMITTEE_APP_AGENDA_ITEM_OVERVIEW: '/agenda-items/',
  NOTIFICATION_APP_OVERVIEW: '/notifications/',
  NOTIFICATION_APP_DASHBOARD: '/notifications/dashboard/',
  NOTIFICATION_APP_WELCOME: '/welcome',
  USERMGMT_APP_PROPOSAL_REQUEST: '/proposal-request/',
  USERMGMT_APP_USERS: '/users/',
  CAMPAIGN_APP_OVERVIEW: '/',
  COORDINATION_APP_OVERVIEW: '/',
  MANUAL_COMPLETION: '/manual-completion',
  COORDINATION_APP_IMPORT_VEHICLES: '/import',
  PARTNER_DATA_APP_OVERVIEW: '/',
  CAMPAIGN_VINS_SEARCHER: '/search-vins',
  ADMIN_CONSOLE_BLOCKED_CAMPAIGN_IDS: '/admin-console/blocked-campaign-ids',
  PENDING_COMPLETION_CODES_OVERVIEW: '/pending-completion-codes',
  CONSUMER_MANAGEMENT: '/',
  CONSUMER_MANAGEMENT_CREATE: '/consumer-detail/new',
  ADMIN_PANEL: '/admin-panel',
};
