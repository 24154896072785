import type { OnDestroy } from '@angular/core';
import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';

import { CommonModalService } from '../modals';
import { SupportModalComponent } from '../support-modal';

@Component({
  selector: 'recall2-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [TranslateModule],
})
export class FooterComponent implements OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(private modalService: CommonModalService) {}

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  openSupportDialog(): void {
    this.modalService.open(SupportModalComponent);
  }
}
