<div class="title">
  <h5 data-cy="modalTitle">
    {{ 'support-modal.title' | translate }}
  </h5>
  <recall2-svg-icon
    name="close"
    size="medium"
    class="rc2-icon--color--primary"
    (click)="onClose()"
  >
  </recall2-svg-icon>
</div>
<div class="rc2-font--body--m">
  <div data-cy="modalContent" class="rc2-mar--right--5xl">
    <span data-cy="modalDescription">
      {{ 'support-modal.description' | translate }}
    </span>
    <a data-cy="supportEmail" [href]="'mailto:' + (isGrpUser ? grpMail : idpMail)">{{ isGrpUser ? grpMail : idpMail }}</a>
  </div>
</div>
<div class="buttons rc2-mar--top--5xl">
  <recall2-button-primary
    data-cy="closeButton"
    (click)="onClose()"
  >
    <span>{{ 'common.close' | translate }}</span>
  </recall2-button-primary>
</div>
