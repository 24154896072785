import { CdkConnectedOverlay, OverlayModule } from '@angular/cdk/overlay';
import { DatePipe, NgClass, NgIf } from '@angular/common';
import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import type { FormControl } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { iconWarningMedium, SVGIconComponent, SVGIconsRegistry } from '@recall2/icons';
import dayjs from 'dayjs';
import { takeUntil } from 'rxjs/operators';

import { DateProperty } from '../form/model';
import { Recall2IconCalendarComponent, Recall2IconInvalidComponent, Recall2IconRequiredComponent } from '../icons';
import { getValidDate, toLocalYearMonthDayString } from '../utils';
import { GuicDatePickerComponent } from './components/guic-datepicker/guic-datepicker.component';
import { FormatDateDisplayPipe } from './pipes/format-date-display.pipe';
import { Recall2DatepickerCommonComponent } from './recall2-datepicker-common.component';

/**Matches string only including digits and dots */
const VALID_FORMAT_REGEX = /^(\d|\.)+$/g;
/**Char different than digits and dots */
const INVALID_CHARS_REGEX = /[^\d.]/g;

@Component({
  selector: 'recall2-datepicker',
  templateUrl: './recall2-datepicker.component.html',
  styleUrls: ['./recall2-datepicker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    GuicDatePickerComponent,
    OverlayModule,
    TranslateModule,
    MatTooltipModule,
    SVGIconComponent,
    DatePipe,
    NgClass,
    NgIf,
    Recall2IconCalendarComponent,
    Recall2IconInvalidComponent,
    Recall2IconRequiredComponent,
    FormatDateDisplayPipe,
  ],
})
export class Recall2DatepickerComponent
  extends Recall2DatepickerCommonComponent<DateProperty>
  implements OnInit, OnChanges
{
  @Input() property: DateProperty;
  @Input() isDisabled: boolean;
  @Input() isFormSubmitted: boolean;
  @Input() isValidateDateDisabled = true;

  @Output() dateChange = new EventEmitter<FormControl>();
  @Output() dateChangeParsed = new EventEmitter<string>();

  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild('calendar', { read: ElementRef }) calendarIcon: ElementRef;
  @ViewChild('guicDatepicker', { read: ElementRef }) guicDatepicker: ElementRef;
  @ViewChild(CdkConnectedOverlay) attachedOverlay: CdkConnectedOverlay;

  isOpen = false;
  inputHasFocus = false;
  selectedDate: Date | null = null;
  readonly dateFormat = 'dd.MM.yyyy';

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    protected iconsRegistry: SVGIconsRegistry,
  ) {
    super(iconsRegistry);
    iconsRegistry.registerIcons([iconWarningMedium]);
  }

  ngOnInit(): void {
    this.onControlValueChanges(this.property.control.value, false);
    this.watchDateControl();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.property?.previousValue?.minDate !== changes.property?.currentValue?.minDate ||
      changes.property?.previousValue?.maxDate !== changes.property?.currentValue?.maxDate
    ) {
      const parsedDate = getValidDate(this.property.control.value);
      this.updateMaxMinValidationErrors(parsedDate);
    }
  }

  onInputBlur(): void {
    this.inputHasFocus = false;
  }

  onInputChange(event: Event | KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    this.setControlAsDirty();
    this.updatePropertyControl(value, false);
  }

  override onClickOutside(event: MouseEvent): void {
    if (!this.isOpen) {
      return;
    }

    const clickedElement = event.target as HTMLElement;

    const clickedInsideInput = this.input.nativeElement.contains(clickedElement);
    const clickedCalendarIcon = this.calendarIcon.nativeElement.contains(clickedElement);
    const clickedInsideCalendar = this.guicDatepicker?.nativeElement.contains(clickedElement);

    if (clickedCalendarIcon && this.isOpen) {
      event.stopPropagation();
      this.closeDatepicker(null);
      return;
    }

    if (clickedInsideInput || clickedInsideCalendar) {
      return;
    }

    this.closeDatepicker(null);
  }

  checkValidKeyPressed(event: KeyboardEvent): void {
    const { key } = event;

    if (key !== '.' && (key === ' ' || Number.isNaN(+key))) {
      event.preventDefault();
    }
  }

  replaceInvalidChars(): void {
    let value = this.input.nativeElement.value;

    if (value !== '' && !VALID_FORMAT_REGEX.test(value)) {
      // eslint-disable-next-line unicorn/prefer-string-replace-all
      value = value.replace(INVALID_CHARS_REGEX, '');
      this.input.nativeElement.value = value;
    }
  }

  onKeyDownPressed(event: KeyboardEvent): void {
    if (!this.isClosingKey(event)) {
      return;
    }

    event.stopPropagation();
    this.closeDatepicker(null);
  }

  closeDatepicker(date?: Date | null): void {
    const inputValue = this.input.nativeElement.value;
    const parsedDate: Date | null = getValidDate(inputValue);
    const dateString = date ? dayjs(date).format('DD.MM.YYYY') : '';

    if (!date && this.getTimeOrNull(this.selectedDate) !== this.getTimeOrNull(parsedDate)) {
      this.setControlAsDirty();
    }

    if (date) {
      this.input.nativeElement.value = dateString;
      if (this.getTimeOrNull(this.selectedDate) !== this.getTimeOrNull(date)) {
        this.setControlAsDirty();
      }
    }

    this.updatePropertyControl(date ? dateString : inputValue, true);

    this.isOpen = false;
    this.input.nativeElement.blur();
    this.cdr.markForCheck();
  }

  detachDatepicker(): void {
    if (this.isOpen) {
      this.closeDatepicker(null);
    }
  }

  get locale(): string {
    if (!this.translate.currentLang) {
      return 'en';
    }
    return this.translate.currentLang.slice(0, 2);
  }

  get showErrors(): boolean {
    if (this.isFormSubmitted === undefined) {
      return this.property.control.invalid && (this.property.control.dirty || this.property.control.touched);
    }

    return this.isFormSubmitted && !!this.property.control.errors;
  }

  get errorType(): string {
    const errorTypes = Object.keys(this.property.control.errors);
    // eslint-disable-next-line unicorn/prefer-at
    return this.showErrors ? errorTypes[errorTypes.length - 1] : undefined;
  }

  private updatePropertyControl(value: string | Date | null, emitExternalEvents: boolean): void {
    const parsedDate: Date | null = value instanceof Date ? value : getValidDate(value);

    if (!value) {
      this.selectedDate = null;
      this.property.control.setValue(null);
    } else if (parsedDate) {
      this.selectedDate = parsedDate;
      this.property.control.setValue(emitExternalEvents ? parsedDate : value);
    } else {
      this.selectedDate = null;
      this.property.control.setValue(value);
    }

    this.updateValidationErrors(value, false);

    if (emitExternalEvents) {
      this.emitDateSelection();
    }

    this.cdr.markForCheck();
  }

  private isClosingKey(event: KeyboardEvent): boolean {
    return ['Enter', 'Escape', 'Tab'].includes(event.key);
  }

  private emitDateSelection(): void {
    this.dateChange.emit(this.property.control);
    this.dateChangeParsed.emit(this.selectedDate ? toLocalYearMonthDayString(this.selectedDate) : '');
  }

  private updateValidationErrors(value: Date | string | null, allowExternalFormat: boolean): void {
    const parsedDate = getValidDate(value, allowExternalFormat);

    if (!value) {
      this.updateControlError('required', this.property.required ? true : null);
      this.updateControlError('invalidDate', null);
    } else if (parsedDate) {
      this.updateControlError('invalidDate', null);
      this.updateControlError('required', null);

      if (!this.isValidateDateDisabled) {
        this.updateMaxMinValidationErrors(parsedDate);
      }
    } else {
      this.updateControlError('invalidDate', true);
      this.updateControlError('required', null);
    }

    this.cdr.markForCheck();
  }

  private updateOverlayPosition(): void {
    if (this.isOpen && this.attachedOverlay?.overlayRef) {
      this.attachedOverlay.overlayRef.updatePosition();
    }
  }

  private updateMaxMinValidationErrors(date: Date | null): void {
    if (this.isValidateDateDisabled) {
      return;
    }

    const dateWithDayjs = dayjs(date);
    this.updateMinDateValidationErrors(dateWithDayjs);
    this.updateMaxDateValidationErrors(dateWithDayjs);
    this.cdr.markForCheck();
  }

  private updateMinDateValidationErrors(date: dayjs.Dayjs): void {
    if (!this.property.minDate) {
      this.updateControlError('minDate', null);
      return;
    }

    const minDate = dayjs(this.property.minDate);
    if (minDate.isValid() && date.isBefore(minDate, 'day')) {
      this.updateControlError('minDate', minDate.format('DD.MM.YYYY'));
    } else {
      this.updateControlError('minDate', null);
    }
  }

  private updateMaxDateValidationErrors(date: dayjs.Dayjs): void {
    if (!this.property.maxDate) {
      this.updateControlError('maxDate', null);
      return;
    }

    const maxDate = dayjs(this.property.maxDate);
    if (maxDate.isValid() && date.isAfter(maxDate, 'day')) {
      this.updateControlError('maxDate', maxDate.format('DD.MM.YYYY'));
    } else {
      this.updateControlError('maxDate', null);
    }
  }

  private watchDateControl(): void {
    this.property.control.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(newValue => this.onControlValueChanges(newValue));

    this.property.control.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.cdr.detectChanges();
      this.updateOverlayPosition();
    });
  }

  private onControlValueChanges(controlValue: Date | string | null, emitEvent = true): void {
    const parsedDate: Date | null = controlValue instanceof Date ? controlValue : getValidDate(controlValue, true);
    if (this.getTimeOrNull(this.selectedDate) !== this.getTimeOrNull(parsedDate)) {
      this.selectedDate = parsedDate;
      this.property.control.setValue(parsedDate, { emitEvent: emitEvent });
      this.updateValidationErrors(controlValue, true);
      this.emitDateSelection();
      return;
    }

    if (typeof controlValue === 'string' && controlValue.length > 0 && parsedDate === null) {
      this.updateValidationErrors(controlValue, true);
    }
  }

  private getTimeOrNull(date: Date | null): number | null {
    return date ? date.getTime() : null;
  }

  private updateControlError(key: string, errorValue: unknown): void {
    const errors = { ...this.property.control.errors };

    if (errorValue === null) {
      delete errors[key];
    } else {
      errors[key] = errorValue;
    }

    this.property.control.setErrors(Object.keys(errors).length > 0 ? errors : null);
  }
}
