import { NgClass, NgFor, NgIf } from '@angular/common';
import type { OnInit } from '@angular/core';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { Recall2IconArrowDownComponent, Recall2IconCheckComponent } from '../icons';
import {
  Recall2ImgAudiComponent,
  Recall2ImgBentleyComponent,
  Recall2ImgBugattiComponent,
  Recall2ImgDucatiComponent,
  Recall2ImgFordComponent,
  Recall2ImgLamborghiniComponent,
  Recall2ImgManComponent,
  Recall2ImgNutzfahrzeugeComponent,
  Recall2ImgPorscheComponent,
  Recall2ImgScaniaComponent,
  Recall2ImgSeatComponent,
  Recall2ImgSkodaComponent,
  Recall2ImgVolkswagenComponent,
} from '../img-logo';
import { ChooseManufacturerComponent } from './components/choose-manufacturer/choose-manufacturer.component';
import type { IBrandManufacturers } from './models/brand-manufacturer-selector.models';
import { IBrandManufacturerSelectorConfiguration } from './models/brand-manufacturer-selector.models';
import { BrandsId } from './models/brands-id.model';
import type { Manufacturer } from './models/manufacturer';
import { IsBrandDisabledPipe } from './pipes/is-disabled-brand.pipe';

@Component({
  selector: 'recall2-brand-manufacturer',
  templateUrl: './brand-manufacturer-selector.component.html',
  styleUrls: ['./brand-manufacturer-selector.component.scss'],
  standalone: true,
  imports: [
    NgFor,
    NgIf,
    NgClass,
    MatTooltipModule,
    TranslateModule,
    ChooseManufacturerComponent,
    IsBrandDisabledPipe,
    Recall2IconArrowDownComponent,
    Recall2IconCheckComponent,
    Recall2ImgAudiComponent,
    Recall2ImgBentleyComponent,
    Recall2ImgPorscheComponent,
    Recall2ImgSkodaComponent,
    Recall2ImgSeatComponent,
    Recall2ImgVolkswagenComponent,
    Recall2ImgNutzfahrzeugeComponent,
    Recall2ImgManComponent,
    Recall2ImgDucatiComponent,
    Recall2ImgScaniaComponent,
    Recall2ImgBugattiComponent,
    Recall2ImgLamborghiniComponent,
    Recall2ImgFordComponent,
  ],
  providers: [IsBrandDisabledPipe],
})
export class BrandManufacturerSelectorComponent implements OnInit {
  @Input() isSubmitted: boolean;
  @Input() brandManufacturerSelectorConfiguration: IBrandManufacturerSelectorConfiguration;
  @Output() updateVisibleManufacturer = new EventEmitter<Manufacturer[]>();

  currentBrand: number | string;
  brandsId = BrandsId;
  forwardedManufacturerIds: number[] = [];

  constructor(private readonly isBrandDisabledPipe: IsBrandDisabledPipe) {}

  ngOnInit(): void {
    this.loadForwardedManufacturerIds();
  }

  getManufacturesEntries(id: number): Manufacturer[] {
    let result = [];
    this.brandManufacturerSelectorConfiguration.brandManufacturers.forEach(entry => {
      if (entry.brand.id === id) {
        result = entry.manufacturers;
      }
    });
    return result;
  }

  updateSelectedManufacturerEntries(updatedSelectedManufacturer: Manufacturer): void {
    let manufacturer = updatedSelectedManufacturer;
    this.brandManufacturerSelectorConfiguration.selectedManufacturers.forEach(entry => {
      if (entry.id === manufacturer.id) {
        manufacturer = entry;
      } // correct object from selected list
    });
    const index = this.brandManufacturerSelectorConfiguration.selectedManufacturers.indexOf(manufacturer, 0);
    if (index > -1) {
      this.brandManufacturerSelectorConfiguration.selectedManufacturers.splice(index, 1);
    } else {
      this.brandManufacturerSelectorConfiguration.selectedManufacturers.push(manufacturer);
    }
    this.updateVisibleManufacturer.emit(this.brandManufacturerSelectorConfiguration.selectedManufacturers);
  }

  selectBrandManufacturer(brandManufacturer: IBrandManufacturers): void {
    if (
      this.isBrandDisabledPipe.transform(
        brandManufacturer,
        this.brandManufacturerSelectorConfiguration.alreadyForwardedManufacturers,
      )
    ) {
      return;
    }

    this.currentBrand = this.currentBrand === brandManufacturer.brand.id ? 0 : brandManufacturer.brand.id;
    if (brandManufacturer.manufacturers.length > 1) {
      return;
    }
    let manufacturer = brandManufacturer.manufacturers[0];

    if (manufacturer.id === this.brandManufacturerSelectorConfiguration.defaultManufacturer.id) {
      return;
    }
    this.brandManufacturerSelectorConfiguration.selectedManufacturers.forEach(entry => {
      if (entry.id === manufacturer.id) {
        manufacturer = entry;
      }
    });

    const index = this.brandManufacturerSelectorConfiguration.selectedManufacturers.indexOf(manufacturer);
    if (index > -1) {
      this.brandManufacturerSelectorConfiguration.selectedManufacturers.splice(index, 1);
    } else {
      this.brandManufacturerSelectorConfiguration.selectedManufacturers.push(manufacturer);
    }
    this.updateVisibleManufacturer.emit(this.brandManufacturerSelectorConfiguration.selectedManufacturers);
  }

  isManufacturerSelected(manufacturers: IBrandManufacturers): boolean {
    if (manufacturers.manufacturers.length > 1) {
      return;
    }
    let result = false;
    const element = this.brandManufacturerSelectorConfiguration.selectedManufacturers.find(
      manufacturer => manufacturer.id === manufacturers.manufacturers[0].id,
    );
    if (element) {
      result = true;
    }
    return result;
  }

  isManufacturerWithMoreSelected(manufacturers: IBrandManufacturers): boolean {
    let result = false;
    manufacturers.manufacturers.forEach(manufacturer => {
      this.brandManufacturerSelectorConfiguration.selectedManufacturers.forEach(entry => {
        if (manufacturer.id === entry.id) {
          result = true;
        }
      });
    });
    return result;
  }

  isManufacturerDefault(manufacturers: IBrandManufacturers): boolean {
    if (manufacturers.manufacturers.length > 1) {
      return;
    }
    return manufacturers.manufacturers[0].id === this.brandManufacturerSelectorConfiguration.defaultManufacturer.id;
  }

  hasBrandMoreManufacturerAndDefault(manufacturers: IBrandManufacturers): boolean {
    let result = false;
    if (manufacturers.manufacturers.length > 1) {
      const element = manufacturers.manufacturers.find(
        manufacturer => manufacturer.id === this.brandManufacturerSelectorConfiguration.defaultManufacturer.id,
      );
      if (element) {
        result = true;
      }
    }
    return result;
  }

  isSelected(id: number): boolean {
    return this.currentBrand === id;
  }

  isStateBrand(brandManufacturer: IBrandManufacturers): boolean {
    let result = this.isStateDefault(brandManufacturer);

    if (!result) {
      brandManufacturer.manufacturers.forEach(entry => {
        this.brandManufacturerSelectorConfiguration.selectedManufacturers.forEach(manufacturer => {
          if (entry.id === manufacturer.id) {
            result = true;
          }
        });
      });
      return result;
    }
    return false;
  }

  isStateDefault(brandManufacturer: IBrandManufacturers): boolean {
    let result = false;

    const element = brandManufacturer.manufacturers.find(
      manufacturer => manufacturer.id === this.brandManufacturerSelectorConfiguration.defaultManufacturer.id,
    );
    if (element) {
      result = true;
    }
    return result;
  }

  checkMoreManufacturersSelected(brandManufacturer: IBrandManufacturers): number {
    let selectedManufacturer = 0;
    const element = brandManufacturer.manufacturers.find(
      manufacturer => manufacturer.id === this.brandManufacturerSelectorConfiguration.defaultManufacturer.id,
    );
    if (element) {
      selectedManufacturer++;
    }
    this.brandManufacturerSelectorConfiguration.selectedManufacturers.forEach(selected => {
      brandManufacturer.manufacturers.forEach(manufacturer => {
        if (manufacturer.id === selected.id) {
          selectedManufacturer++;
        }
      });
    });
    return selectedManufacturer;
  }

  isSelectedWithDefault(brandManufacturer: IBrandManufacturers): string {
    let result = 'selected-manufacturer';

    const element = brandManufacturer.manufacturers.find(
      manufacturer => manufacturer.id === this.brandManufacturerSelectorConfiguration.defaultManufacturer.id,
    );
    if (element) {
      result = 'default-manufacturer';
    }
    return result;
  }

  private loadForwardedManufacturerIds(): void {
    (this.brandManufacturerSelectorConfiguration.alreadyForwardedManufacturers ?? []).forEach(brand => {
      this.forwardedManufacturerIds = [...this.forwardedManufacturerIds, ...brand.forwardedManufacturerIds];
    });
  }
}
