import type { OnDestroy, OnInit } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import type { FormControl } from '@angular/forms';
import { filter, Subject, takeUntil } from 'rxjs';

import { Recall2DatepickerComponent } from '../../../../../datepicker/recall2-datepicker.component';
import { DateProperty } from '../../../../../form/model';
import { MoreFiltersScrollService } from '../../../../../table/components/more-filters/services/scroll/more-filters-scroll.service';
import { getValidDate } from '../../../../../utils/get-valid-date/get-valid-date.utils';

//TODO: Might be unified with FilterDateRangeV2
export interface FilterDateRange {
  from: Date;
  to: Date;
  invalidState: boolean;
}

@Component({
  selector: 'recall2-filter-date-range',
  templateUrl: './recall2-filter-date-range.component.html',
  styleUrls: ['./recall2-filter-date-range.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [Recall2DatepickerComponent],
})
export class Recall2FilterDateRangeComponent implements OnInit, OnDestroy {
  @Input() fromDateProperty: DateProperty;
  @Input() toDateProperty: DateProperty;
  @Output() dateRangeSelected = new EventEmitter<FilterDateRange>();

  @ViewChild('datePickerFromInput') datePickerFromInput: Recall2DatepickerComponent;
  @ViewChild('datePickerToInput') datePickerToInput: Recall2DatepickerComponent;

  private destroyed$ = new Subject<void>();

  constructor(private moreFiltersScrollService: MoreFiltersScrollService) {}

  ngOnInit(): void {
    this.setInitialMaxAndMinDates();
    this.watchMoreFiltersScroll();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  fromDateChange(dateControl: FormControl): void {
    const dateValue = dateControl.value;
    if (dateValue) {
      const minDate = getValidDate(dateValue);
      this.toDateProperty = { ...this.toDateProperty, minDate };
    }
    this.emitOutput();
  }

  toDateChange(dateControl: FormControl): void {
    const dateValue = dateControl.value;
    if (dateValue) {
      const maxDate = getValidDate(dateValue);
      this.fromDateProperty = { ...this.fromDateProperty, maxDate };
    }
    this.emitOutput();
  }

  private setInitialMaxAndMinDates(): void {
    const selectedFromInitialValue = this.fromDateProperty.control.value;
    const selectedToInitialValue = this.toDateProperty.control.value;

    if (selectedToInitialValue) {
      this.fromDateProperty = { ...this.fromDateProperty, maxDate: selectedToInitialValue };
    }

    if (selectedFromInitialValue) {
      this.toDateProperty = { ...this.toDateProperty, minDate: selectedFromInitialValue };
    }
  }

  private isFormValid(): boolean {
    return this.toDateProperty.control.valid && this.fromDateProperty.control.valid;
  }

  private emitOutput(): void {
    const invalidState = !this.isFormValid();
    const from = getValidDate(this.fromDateProperty.control.value);
    const to = getValidDate(this.toDateProperty.control.value);

    this.dateRangeSelected.emit({ from, to, invalidState });
  }

  private watchMoreFiltersScroll(): void {
    this.moreFiltersScrollService.scroll$
      .pipe(
        takeUntil(this.destroyed$),
        filter(() => this.datePickerFromInput?.isOpen || this.datePickerToInput?.isOpen),
      )
      .subscribe(() => {
        this.datePickerFromInput?.attachedOverlay?.overlayRef?.detach();
        this.datePickerToInput?.attachedOverlay?.overlayRef?.detach();
      });
  }
}
