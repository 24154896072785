export enum AvailableAps {
  Volkswagen = 'volkswagen',
  VolkswagenMsk = 'volkswagen-msk',
  Nutzfahrzeuge = 'nutzfahrzeuge',
  Audi = 'audi',
  Skoda = 'skoda',
  Seat = 'seat',
  Bentley = 'bentley',
  Porsche = 'porsche',
  Lamborghini = 'lamborghini',
  Man = 'man',
  Ford = 'ford',
}
