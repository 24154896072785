import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

/**
 * Converts a Date or string to a string with format "YYYY-MM-DD", following BE needs.
 * @param date It could be a date or a string formatted(e.g, 'DD.MM.YYYY').
 * @param format Optional. We can pass the format of string.
 */

export const toLocalYearMonthDayString = (date: Date | string, format?: string): string => {
  if (!date) {
    return null;
  }

  if (date instanceof Date) {
    return dayjs(date).format('YYYY-MM-DD');
  } else {
    dayjs.extend(customParseFormat);
    return dayjs(dayjs(date, format)).format('YYYY-MM-DD');
  }
};
