import { ENavbarItem } from '../models/navbar-item.model';
import { ERole } from '../models/role.model';

const navbarItemRoleMapping: Map<ENavbarItem, ERole[]> = new Map<ENavbarItem, ERole[]>()
  .set(ENavbarItem.PRELIMINARY, [
    ERole.COORDINATOR_TOPIC,
    ERole.COORDINATOR_TOPIC_VIEWER,
    ERole.COORDINATOR_BRAND_OFFICE,
    ERole.COORDINATOR_BRAND_OFFICE_VIEWER,
    ERole.COORDINATOR_CLEARING,
    ERole.COORDINATOR_CLEARING_VIEWER,
    ERole.COMMITTEE_MEMBER,
    ERole.COMMITTEE_MEMBER_VIEWER,
    ERole.CAMPAIGN_AUTHOR,
    ERole.CAMPAIGN_AUTHOR_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_BRAND,
    ERole.CAMPAIGN_COORDINATOR_BRAND_VIEWER,
    ERole.REPORTER,
    ERole.REVIEWER,
    ERole.EXPERT,
  ])
  .set(ENavbarItem.TOPIC, [
    ERole.COORDINATOR_TOPIC,
    ERole.COORDINATOR_TOPIC_VIEWER,
    ERole.COORDINATOR_BRAND_OFFICE,
    ERole.COORDINATOR_BRAND_OFFICE_VIEWER,
    ERole.COORDINATOR_CLEARING,
    ERole.COORDINATOR_CLEARING_VIEWER,
    ERole.COMMITTEE_MEMBER,
    ERole.COMMITTEE_MEMBER_VIEWER,
    ERole.CAMPAIGN_AUTHOR,
    ERole.CAMPAIGN_AUTHOR_VIEWER,
  ])
  .set(ENavbarItem.CLEARING, [
    ERole.COORDINATOR_TOPIC,
    ERole.COORDINATOR_TOPIC_VIEWER,
    ERole.COORDINATOR_BRAND_OFFICE,
    ERole.COORDINATOR_BRAND_OFFICE_VIEWER,
    ERole.COORDINATOR_CLEARING,
    ERole.COORDINATOR_CLEARING_VIEWER,
    ERole.COMMITTEE_MEMBER,
  ])
  .set(ENavbarItem.VERIFICATION_TASK, [
    ERole.VERIFICATION_TASK_EDITOR,
    ERole.COORDINATOR_TOPIC,
    ERole.COORDINATOR_TOPIC_VIEWER,
    ERole.COORDINATOR_CLEARING,
    ERole.COORDINATOR_CLEARING_VIEWER,
    ERole.COORDINATOR_BRAND_OFFICE_VIEWER,
    ERole.CAMPAIGN_AUTHOR_VIEWER,
    ERole.COMMITTEE_MEMBER_VIEWER,
    ERole.COMMITTEE_MEMBER,
  ])
  .set(ENavbarItem.COMMITTEE, [
    ERole.COORDINATOR_BRAND_OFFICE,
    ERole.COORDINATOR_BRAND_OFFICE_VIEWER,
    ERole.COORDINATOR_CLEARING,
    ERole.COORDINATOR_CLEARING_VIEWER,
    ERole.COORDINATOR_TOPIC,
    ERole.COORDINATOR_TOPIC_VIEWER,
  ])
  .set(ENavbarItem.MEETING, [
    ERole.COORDINATOR_BRAND_OFFICE,
    ERole.COORDINATOR_BRAND_OFFICE_VIEWER,
    ERole.COORDINATOR_TOPIC,
    ERole.COORDINATOR_TOPIC_VIEWER,
    ERole.COORDINATOR_CLEARING,
    ERole.COORDINATOR_CLEARING_VIEWER,
    ERole.COMMITTEE_MEMBER,
    ERole.COMMITTEE_MEMBER_VIEWER,
  ])
  .set(ENavbarItem.AGENDA_ITEM, [
    ERole.COORDINATOR_BRAND_OFFICE,
    ERole.COORDINATOR_BRAND_OFFICE_VIEWER,
    ERole.COORDINATOR_CLEARING,
    ERole.COORDINATOR_CLEARING_VIEWER,
    ERole.COMMITTEE_MEMBER,
    ERole.COMMITTEE_MEMBER_VIEWER,
    ERole.CAMPAIGN_AUTHOR,
    ERole.CAMPAIGN_AUTHOR_VIEWER,
    ERole.COORDINATOR_TOPIC,
    ERole.COORDINATOR_TOPIC_VIEWER,
  ])
  .set(ENavbarItem.CAMPAIGN, [ERole.CAMPAIGN_AUTHOR, ERole.CAMPAIGN_AUTHOR_VIEWER])
  .set(ENavbarItem.PARTNER_DATA, [
    ERole.ADMIN_MANUFACTURER,
    ERole.CAMPAIGN_AUTHOR,
    ERole.CAMPAIGN_AUTHOR_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_BRAND,
    ERole.CAMPAIGN_COORDINATOR_BRAND_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_IMPORTER,
    ERole.CAMPAIGN_COORDINATOR_IMPORTER_VIEWER,
  ])
  .set(ENavbarItem.COORDINATION, [
    ERole.CAMPAIGN_AUTHOR,
    ERole.CAMPAIGN_AUTHOR_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_BRAND,
    ERole.CAMPAIGN_COORDINATOR_BRAND_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_IMPORTER,
    ERole.CAMPAIGN_COORDINATOR_IMPORTER_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_DEALER,
    ERole.CAMPAIGN_COORDINATOR_DEALER_VIEWER,
  ])
  .set(ENavbarItem.COORDINATION_OVERVIEW, [
    ERole.CAMPAIGN_AUTHOR,
    ERole.CAMPAIGN_AUTHOR_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_BRAND,
    ERole.CAMPAIGN_COORDINATOR_BRAND_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_IMPORTER,
    ERole.CAMPAIGN_COORDINATOR_IMPORTER_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_DEALER,
    ERole.CAMPAIGN_COORDINATOR_DEALER_VIEWER,
  ])
  .set(ENavbarItem.COORDINATION_IMPORT, [ERole.CAMPAIGN_COORDINATOR_IMPORTER])
  .set(ENavbarItem.COORDINATION_IMPORT_VEHICLES, [
    ERole.CAMPAIGN_AUTHOR,
    ERole.CAMPAIGN_AUTHOR_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_BRAND,
    ERole.CAMPAIGN_COORDINATOR_BRAND_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_IMPORTER,
  ])
  .set(ENavbarItem.USER_MANAGEMENT, [ERole.ADMIN, ERole.ADMIN_VIEWER, ERole.ADMIN_ROOT])
  .set(ENavbarItem.ADMIN_PANEL, [ERole.ADMIN, ERole.ADMIN_VIEWER, ERole.ADMIN_ROOT])
  .set(ENavbarItem.USER_MANAGEMENT_SUB_ADMIN, [ERole.ADMIN, ERole.ADMIN_VIEWER])
  .set(ENavbarItem.USER_MANAGEMENT_SUB_ADMIN_ROOT, [ERole.ADMIN_ROOT])
  .set(ENavbarItem.PENDING_COMPLETION_CODES, [
    ERole.CAMPAIGN_COORDINATOR_BRAND,
    ERole.CAMPAIGN_COORDINATOR_BRAND_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_IMPORTER,
    ERole.CAMPAIGN_COORDINATOR_IMPORTER_VIEWER,
  ])
  .set(ENavbarItem.MANUAL_COMPLETION, [
    ERole.CAMPAIGN_COORDINATOR_BRAND,
    ERole.CAMPAIGN_COORDINATOR_BRAND_VIEWER,
    ERole.CAMPAIGN_COORDINATOR_IMPORTER,
    ERole.CAMPAIGN_COORDINATOR_IMPORTER_VIEWER,
  ])
  .set(ENavbarItem.CONSUMER_MANAGEMENT, [ERole.ADMIN_ROOT]);

export const allRoles: ERole[] = Object.values(ERole);

export function hasObjectReadAccess(currentItem: ENavbarItem, roles: ERole[]): boolean {
  return navbarItemRoleMapping.get(currentItem)?.some(role => roles?.includes(role)) ?? false;
}

export function isGrpUser(roles: ERole[]): boolean {
  return roles?.some(
    role =>
      role === ERole.CAMPAIGN_COORDINATOR_IMPORTER ||
      role === ERole.CAMPAIGN_COORDINATOR_IMPORTER_VIEWER ||
      role === ERole.CAMPAIGN_COORDINATOR_DEALER ||
      role === ERole.CAMPAIGN_COORDINATOR_DEALER_VIEWER,
  );
}
