<mat-table
  class="rc2-font--family--regular"
  *ngIf="files.length > 0"
  [dataSource]="files"
  [ngClass]="{'rc2-bg--grey-800-primary': isOverlay}"
>
  <ng-container matColumnDef="download">
    <mat-cell *matCellDef="let file">
      <recall2-icon-download
        (click)="download.emit(file)"
        class="rc2-cursor--pointer"
        [class.disabled-action]="loading"
      >
      </recall2-icon-download>
    </mat-cell>
    <mat-footer-cell *matFooterCellDef>
      <recall2-icon-download
        (click)="downloadAll.emit()"
        class="rc2-cursor--pointer"
        [class.disabled-action]="loading"
      >
      </recall2-icon-download>
    </mat-footer-cell>
  </ng-container>
  <ng-container matColumnDef="filename">
    <mat-cell *matCellDef="let file">
      <span
        class="rc2-ellipsis"
        [ngClass]="{'rc2-font--family--bold rc2-color--primary': isOverlay}"
      >
        {{ file.filename }}
      </span>
    </mat-cell>
    <ng-container>
      <mat-footer-cell
        [ngClass]="{'rc2-font--family--bold  rc2-color--primary': isOverlay}"
        *matFooterCellDef
      >
        {{ !isOverlay ? ('attachments.downloadAll' | translate) : ('attachments.download' | translate) }}
      </mat-footer-cell>
    </ng-container>
  </ng-container>
  <ng-container matColumnDef="extension">
    <mat-cell *matCellDef="let file">
      {{ file.extension }}
    </mat-cell>
    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
  </ng-container>
  <ng-container matColumnDef="size">
    <mat-cell *matCellDef="let file">
      {{ file.size | fileSize }}
    </mat-cell>
    <mat-footer-cell *matFooterCellDef>{{ totalSize | fileSize }}</mat-footer-cell>
  </ng-container>
  <ng-container matColumnDef="delete">
    <mat-cell *matCellDef="let file">
      <recall2-icon-delete
        (click)="onDelete($event, file)"
        class="rc2-cursor--pointer"
        [class.disabled-action]="loading"
      >
      </recall2-icon-delete>
    </mat-cell>
    <mat-footer-cell *matFooterCellDef></mat-footer-cell>
  </ng-container>
  <mat-row *matRowDef="let file; columns: displayedColumns"></mat-row>
  <mat-footer-row
    [ngClass]="{'rc2-bg--grey-800-primary': isOverlay, 'hidden': files.length < 2}"
    *matFooterRowDef="displayedColumns"
  >
  </mat-footer-row>
</mat-table>
