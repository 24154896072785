const DATE_DD_MM_YYYY_REGEX = /^(?:\d{1,2}\.){2}\d{4}$/;
const DATE_ISO_8601_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{3})?Z$/; //ISO 8601 Date with optional milliseconds
const DATE_YYYY_MM_DD_REGEX = /^\d{4}-\d{2}-\d{2}$/;

export const getValidDate = (dateValue: string | Date, allowExternalFormat = false): Date | null => {
  if (!dateValue) {
    return null;
  }

  if (dateValue instanceof Date) {
    // eslint-disable-next-line unicorn/prefer-number-properties
    return isNaN(dateValue.getTime()) ? null : dateValue;
  }

  if (allowExternalFormat && DATE_YYYY_MM_DD_REGEX.test(dateValue)) {
    return parseDateString(dateValue, 'yyyy-mm-dd');
  }

  if (DATE_DD_MM_YYYY_REGEX.test(dateValue)) {
    return parseDateString(dateValue, 'dd.mm.yyyy');
  }

  if (isIsoDate(dateValue)) {
    return new Date(dateValue);
  }

  return null;
};

const parseDateString = (dateString: string, format: 'dd.mm.yyyy' | 'yyyy-mm-dd'): Date | null => {
  const [part1, part2, part3] = dateString.split(format === 'dd.mm.yyyy' ? '.' : '-').map(Number);
  const [day, month, year] = format === 'dd.mm.yyyy' ? [part1, part2, part3] : [part3, part2, part1];

  const date = new Date(year, month - 1, day);
  return isValidDate(date, day, month, year) ? date : null;
};

const isIsoDate = (dateValue: string): boolean => {
  if (!DATE_ISO_8601_REGEX.test(dateValue)) {
    return false;
  }

  const parsedDate = new Date(dateValue);
  // eslint-disable-next-line unicorn/prefer-number-properties
  if (isNaN(parsedDate.getTime())) {
    return false;
  }

  const parsedISO = parsedDate.toISOString();

  return parsedISO.startsWith(dateValue.replace(/Z$/, ''));
};

const isValidDate = (date: Date, day: number, month: number, year: number): boolean => {
  return (
    date instanceof Date &&
    // eslint-disable-next-line unicorn/prefer-number-properties
    !isNaN(date.getTime()) &&
    date.getDate() === day &&
    date.getMonth() === month - 1 &&
    date.getFullYear() === year
  );
};
